<template>
  <div class="notification-create card">
    <h5>{{ t('createNewNotification') }}</h5>
    <div class="row">
      <div class="col-lg-6">
        <div class="inputs">
          <div class="input-wrap">
            <label>{{ t('title') }} <span class="required-star">*</span></label>
            <input
              type="text"
              v-model="state.title"
              class="form-control"
              :placeholder="t('title')"
            />
          </div>
          <div class="input-wrap">
            <label>{{ t('targetUrl') }}</label>
            <input
              type="text"
              v-model="state.targetUrl"
              class="form-control"
              :placeholder="t('targetUrl')"
            />
          </div>
          <div class="input-wrap">
            <label>{{ t('iconUrl') }} </label>
            <input
              type="text"
              v-model="state.iconUrl"
              class="form-control"
              id="title"
              :placeholder="t('iconUrl')"
            />
          </div>
          <div class="input-wrap">
            <label>{{ t('imgUrl') }}</label>
            <input
              type="text"
              v-model="state.imageUrl"
              class="form-control"
              id="title"
              :placeholder="t('imgUrl')"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="input-wrap">
          <label>{{ t('notificationContent') }} <span class="required-star">*</span></label>
          <textarea
            name="content"
            v-model="state.content"
            class="form-control"
            id="content"
            rows="8"
            :placeholder="t('notificationContent')"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button @click="onSubmit" :disabled="isFormInvalid" class="btn btn-primary">
            {{ t('create.create') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import useGlobal from '../../composables/useGlobal'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'NotificationCreate',
  props: {
    newNotification: {
      type: Object,
      default: () => ({
        title: '',
        content: '',
        targetUrl: '',
        iconUrl: '',
        image_url: '',
      }),
    },
  },
  setup(props) {
    const { restApi, successNotify, errorNotify, emitter } = useGlobal()

    const state = reactive({
      title: props.newNotification.title,
      content: props.newNotification.content,
      targetUrl: props.newNotification.targetUrl,
      iconUrl: props.newNotification.iconUrl,
      imageUrl: props.newNotification.imageUrl,
    })

    watch(state, () => {
      emitter.emit('notification-create-changed', state)
    })

    const onSubmit = () => {
      restApi.addTask(
        state.title,
        state.content,
        state.targetUrl,
        state.iconUrl,
        state.imageUrl,
      ).then(() => {
        successNotify(
          'Yeni bildirim oluşturuldu',
          'Yeni bildirim başarıyla oluşturuldu.',
        )
      }).catch(() => {
        errorNotify(
          'Yeni bildirim oluşturulamadı',
          'Yeni bildirim oluşturulurken bir hata oluştu.',
        )
      })
    }

    const rules = {
      title: [required],
      content: [required],
    }

    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const { t } = useGlobal()

    return {
      state,
      t,
      onSubmit,

      isFormInvalid,
    }
  },
}
</script>

<style scoped>

h5 {
  margin-bottom: 1em;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.pre {
  background-color: #fdfafa;
  padding: 1em;
  border-radius: 4px;
  font-size: 0.8em;
}

.code {
  font-weight: bold;
  text-decoration: none;
}

.form .row {
  margin-top: 2em;
}

.form .inputs {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
</style>
